namespace Validation
{
    const inputErrorClass: string = "input-validation-error";

    const getContainer = ($container: JQuery): JQuery =>
    {
        if ($container == null)
        {
            $container = $(document.body);
        }
        return $container;
    }

    const getValidationAlertsContainer = () =>
    {
        return getJQuery({ containerFor: "validationAlerts" });
    }

    const removeControlHighlights = ($container: JQuery) =>
    {
        $container.find("input").removeClass(inputErrorClass);
        $container.find("select").removeClass(inputErrorClass);
        $container.find("[data-validation-for]").removeClass(inputErrorClass);
    }

    export const showAlerts = (errors: Error[], $container?: JQuery) =>
    {
        $container = getContainer($container);
        removeControlHighlights($container);
        showValidationHeader();
        highlightControls();

        function showValidationHeader()
        {
            Templates.updateContainer(getValidationAlertsContainer(), { data: errors });

            $container.getJQuery({ controlFor: "validationAlert" }).addClickEvent(validationAlertClick);

            function validationAlertClick($element: JQuery)
            {
                const key = $element.data("id");
                let $foundControl = $container.find(`[name='${key}']`);

                if (!$foundControl.length)
                {
                    $foundControl = $container.find(`[data-validation-for='${key}']`);
                }

                if ($foundControl.length)
                {
                    if ($foundControl.attr("type") === "hidden")
                    {
                        scrollToControl($foundControl.parent());
                    }
                    else
                    {
                        scrollToControl($foundControl);
                        $foundControl.focus();
                    }
                }
            }

            function scrollToControl($element: JQuery)
            {
                const elementOffsetTop = $element.offset().top;
                const elementHeight = $element.height();
                const windowHeight = $(window).height();
                let offset: number;

                if (elementHeight < windowHeight)
                {
                    offset = elementOffsetTop - ((windowHeight / 2) - (elementHeight / 2));
                }
                else
                {
                    offset = elementOffsetTop;
                }

                $("html, body").scrollTop(offset);
            }
        }

        function highlightControls()
        {
            errors.forEach(
                (error: Error) =>
                {
                    const $foundControl = $container.find(`[name='${error.Key}']`);

                    if ($foundControl.length)
                    {
                        $foundControl.addClass(inputErrorClass);
                    }

                    const $foundAltControl = $container.find(`[data-validation-for='${error.Key}']`);

                    if ($foundAltControl.length)
                    {
                        $foundAltControl.addClass(inputErrorClass);
                    }
                }
            );
        }
    }

    export const clearAlerts = ($container?: JQuery) =>
    {
        $container = getContainer($container);

        removeControlHighlights($container);

        Templates.clearContainer(getValidationAlertsContainer());
    }

    // ReSharper disable once InconsistentNaming
    export interface Error
    {
        // ReSharper disable once InconsistentNaming
        Key: string;
        // ReSharper disable once InconsistentNaming
        Message: string;
    }


}